import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDrag } from 'react-dnd';
import classNames from 'classnames';

import { CHARACTERISTIC_ITEM_TYPE } from '../../constants';
import { Pearl } from '../Pearl';
import './styles.scss';

export const Characteristic = (props) => {
  const { id, color, fontColor, bottomBorderColor, bottomBorderStyle, count, draggable, showPearl, nodeId } = props;
  const {
    text, pearl, isCorrect, type, isIncorrect,
  } = useSelector((state) => state.characteristics.byId[id]) || {};

  const backColor = "linear-gradient(0deg," + color+ "," + color + "), #FFFFFF";
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: CHARACTERISTIC_ITEM_TYPE,
      id,
      text,
      charType: type,
      curLocation: nodeId
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      
    }),
  });





  return (
    <div
      ref={draggable ? drag : undefined}
      className={classNames('characteristic', {
        'correct': isCorrect,
        'incorrect': isIncorrect,
        'has-count': count > 1,
      })}
      style={{
        background: backColor,
        color: fontColor,
        borderBottomColor: bottomBorderColor,
        borderBottomStyle: bottomBorderStyle,
        boxShadow: "0px 6px 12px rgba(54, 123, 245, 0.16)",
        visibility: isDragging ? 'hidden' : 'visible' 
     }} 
    >
      <div 
        dangerouslySetInnerHTML={{ __html: text }} 
        className="characteristicText"
      />
      {showPearl && pearl && 
        <div 
          className="pearlDiv"
          style = {{ 
          }}
        >
           <Pearl text={pearl} />
        </div>
      }
      {count > 1 && (
        <div 
          className="count"
          style = {{ 
          }}
        >
          {count}
        </div>
      )}
    </div>
  );
};



Characteristic.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  draggable: PropTypes.bool,
  showPearl: PropTypes.bool,
  count: PropTypes.number,
};

Characteristic.defaultProps = {
  color: 'none',
  draggable: false,
  showPearl: false,
  count: 1,
};
