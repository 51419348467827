import React, { useEffect, useMemo } from 'react';
import firebase from 'firebase/app';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { LandingHeader } from '../../components/LandingHeader';


import 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

import { useAuthDataContext } from '../../services/authDataProvider';
import '../LoginPage/styles.scss';
import mixpanel from 'mixpanel-browser';




const uiConfig = {
  signInSuccessUrl: '/',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      fullLabel: 'Join with Google'
    }
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: '/tos',
  // Privacy policy url/callback.
  privacyPolicyUrl: '/privacypolicy',
};

export const SignupPage = () => {
  //mixpanel.track('Signup page view');
  const { loginUI } = useAuthDataContext();
  useEffect(() => {
    if (loginUI) {
      loginUI.start('#firebaseui-auth-container', uiConfig);
    }
  }, [loginUI]);

  return (
    <div id="firebaseui-auth-container" className="signupPage">
      <LandingHeader/>
      <div className="loginContent">
      
        <p className="loginTitle"> Sign up </p>
        <p className="signinOption"> Already have an account? <Link to={"/login"} className="signinLink"> Sign in</Link> 
        </p>
      </div>
    </div>
  );
};
