import React, { useCallback, useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useAuthDataContext } from '../../services/authDataProvider';
import { LoginPage } from '../../scenes/LoginPage';
import { nodePath } from '../../services';
import { LoadingScreen } from '../../components/LoadingScreen';
import firebase from '../../services/firebase';



export const PrivateRoute = ({ authenticated, component, ...options }) => {
  //const { authenticated } = props;
  
  const [user, setUser] = useState(() => firebase.auth().currentUser || undefined);
  const loadingUser = (user === undefined);

  useEffect(() => firebase.auth().onAuthStateChanged(setUser), []);

  if (loadingUser) {
    return <LoadingScreen />; // or show loading icon, etc.
  }
  


  return <Route {...options} component={ !!user ?  component : LoginPage } />
  
};