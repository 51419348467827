import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';


import { NavigationDropdown } from '../NavigationDropdown';
import { useNodeLineage } from '../../hooks/useNodeLineage';
import { nodePath } from '../../services';
import { Pearl } from '../Pearl';
import { logVisitView, logLeaveView } from '../../services/analytics';
import './styles.scss';

export const NavigationBar = ({ headNodeId }) => {
  const nodeLineage = useNodeLineage(headNodeId);
  const nodeLineageIds = nodeLineage.map(node => node.id)
  mixpanel.register({
        "View ancestors": nodeLineageIds,
  });

  const handleBreadcrumbNav = useCallback((node) => {
   const data = {
      navMode: 'Breadcrumb',
      oldView: headNodeId,
      newView: node
    };
    logLeaveView(data);
    logVisitView(data);

  }, []);

  return (
    <div className="navigation-bar">
      <Breadcrumb separator="|">
        {nodeLineage.map((node, index) => (
          index > 0 ? 
          <Breadcrumb.Item
            key={node.id}
            overlay={index > 0 && <NavigationDropdown headNodeId={headNodeId} nodeId={node.id} />}
            onClick={() => handleBreadcrumbNav(node.id)}
          >
            <Link 
              to={nodePath(node.id)}
              className="navLink"
            >
              {node.title}
            </Link>
          </Breadcrumb.Item>
          : ''
        ))}
      </Breadcrumb>
    </div>
  );
};

NavigationBar.propTypes = {
  headNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
