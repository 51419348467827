import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { PlusOutlined, getTwoToneColor, setTwoToneColor  } from '@ant-design/icons';
import classNames from 'classnames';

import { CHARACTERISTIC_ITEM_TYPE } from '../../constants';
import './styles.scss';
setTwoToneColor('blue');
export const DroppableCategory = (props) => {
  const { children, nodeId, onDrop } = props;
  const style: CSSProperties = {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    minHeight: "100px",
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "250px",
    marginBottom: "50px"
  }
  setTwoToneColor('#367BF5');
  const iconStyle: CSSProperties = {
    fontSize: '20px', 
    color: 'white',
    backgroundColor: getTwoToneColor(),
    borderRadius: "50%",
    marginRight: "auto",
    marginLeft: "auto",
  }


  const [{ canDrop, isOver }, drop] = useDrop({
    accept: CHARACTERISTIC_ITEM_TYPE,
    drop: (item) => onDrop(nodeId, item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    
  });

  const isActive = canDrop && isOver
    let border = "2px dashed #367BF5";
    let display = "none";
    if (canDrop && isActive) {
      border = "2px solid #367BF5";
      display = "inline";
    } 
    

  return (
    <div 
      className= "droppable-category"
      key = {`droppable-category ${nodeId}` }
      ref={drop}
      style={{ ...style, border }}
    >
      <PlusOutlined style = {{ ...iconStyle, display}} />
      {children}
    </div>
  );
};

DroppableCategory.propTypes = {
  children: PropTypes.node.isRequired,
  nodeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onDrop: PropTypes.func,
};

DroppableCategory.defaultProps = {
  onDrop: () => {},
};
