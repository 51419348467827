import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import { Pearl } from '../Pearl';
import { nodePath } from '../../services';

import './styles.scss';
import { logNodeClicked, logVisitView, logLeaveView } from '../../services/analytics';

export const ConceptNode = (props) => {
  const { nodeId, showPearl, marginBottom, handleDiagramNav } = props;

  const nodeData = useSelector((state) => state.conceptNodes.byId[nodeId]);
  const handleDiNav = useCallback(() => {
     const data = {
      navMode: 'Diagram',
      newView: nodeId,
    };
    logVisitView(data);
  }, []);

  if (!nodeData) return null;
  
  


  const { children = [] } = nodeData;
  const pearl = showPearl ? nodeData.pearl : null;
  const { linkable } = nodeData;


  const renderNode = (
    <div className={`concept-node concept-node-${nodeId}`} >
      {nodeData.title}
      {pearl && <Pearl text={pearl} />}
    </div>
  );

  const renderLinkNode = (
    <div className={`concept-node-linkable concept-node-${nodeId}`} 
    //onClick = {handleDiNav}
    >
      {nodeData.title}
      {pearl && <Pearl text={pearl} />}
    </div>
  );

  return (
    <div 
      className="concept-node-container"
      onClick={handleDiNav}
    >
      {(children.length > 0 && linkable !== false) ? (
        <Link 
          to={nodePath(nodeId)}
          className = "diagramLink" 
        >
          {renderLinkNode}
        </Link>
      ) : (
        renderNode
        )}
    </div>
  );
};

ConceptNode.propTypes = {
  nodeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  showPearl: PropTypes.bool,
};

ConceptNode.defaultProps = {
  showPearl: false,
};
