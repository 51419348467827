import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useCorrectQuiz = () => {
  const nodesById = useSelector((state) => state.conceptNodes.byId);
  const characteristicsById = useSelector((state) => state.characteristics.byId);

  return useCallback((characteristicIdsByNodeId) => {
    const correct = [];
    const incorrect = [];

    Object.keys(characteristicIdsByNodeId).forEach((nodeId) => {
      characteristicIdsByNodeId[nodeId].forEach((characteristicId) => {
        const nodeCharacteristicIds = nodesById[nodeId].characteristics || [];
        const nodeCharacteristics = nodeCharacteristicIds.map(id => characteristicsById[id]);
        const characteristic = characteristicsById[characteristicId.id];
        const isCorrect = !!nodeCharacteristics.find(
          ({ text }) => text === characteristic.text
        );

        if (isCorrect) {
          correct.push(characteristic);
        } else {
          incorrect.push(characteristic);
        }
      });
    });

    return { correct, incorrect };
  }, [nodesById, characteristicsById]);
};
