import { Layout, Row, Col, Image, Button, Dropdown, Menu} from 'antd';
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';

import { Header } from '../../components/Header';
import { TeamSection } from '../../components/TeamSection';
import { ValueProps } from '../../components/ValueProps';
import { LandingHeader } from '../../components/LandingHeader';
import mixpanel from 'mixpanel-browser';
import '../../fonts/stylesheet.css';
import './styles.scss';


export const LandingPage = () => {
  //mixpanel.track('Landing page view');
  window.intercomSettings = {
    app_id: "cklemr0u"
  };
// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/cklemr0u'
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/cklemr0u';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  return (
  	
  	<div id="landingPage" className="landingPage">
  		
  		<LandingHeader/>

      <Row gutter={20} className="bannerRow introSection">
        <Col xs={{ span: 18, offset: 3}} md={{ span: 12, offset:0 }} className="titleSlogan">
         
          <h2 className="bannerTitle"> Studying redesigned <span style={{display:'inline-block'}}>for med students</span> </h2>
          <p className="bannerSubTitle">
      	    with distilled, high-yield content <span className="inline-above-mobile">for the shelf and step exams.</span></p>
          <p className="bannerFreeOffer"> Free lifetime subscription <span style={{display:'inline-block'}}>for all early users.</span></p>
					<div className="headerCTAs">
						<Link to={"/signup"} className="signupButtonLink largeScreenOnly blueCTA headerCTA"> 
							Sign up now
						</Link> 
						<div type="primary" shape="round" className="mobileOnly blueCTA headerCTA" >
							Please expand your window or return on a larger screen for optimized learning.
						</div>
					</div>
        </Col>

        <Col xs={{ span: 22, offset: 1}} md={{ span: 11}} className="titleImage">
          <div className="appImageAndBackground">
	          <Image 
	            className = "appExampleImage"
	            src={'https://storage.googleapis.com/study_buckets_images/LandingPage/rotating_images_v2.gif'}
	            preview={false} 
	          />
	          <div className="dotted-box" style={{top:'-220px',left:'14px'}}></div>
	          <div className="dotted-box" style={{top:'-50px',right:'-20px'}}></div>
	          <div className="dotted-box" style={{top:'-100px',right:'-345px'}}></div>
	          <div className="dotted-box" style={{top:'70%',left:'-20px'}}></div>
	          <div className="dotted-box" style={{bottom:'-85px',right:'20px'}}></div>
	          <div className="dotted-box" style={{top:'50%',right:'-290px'}}></div>
        	</div>
        </Col>
      </Row>

      <ValueProps /> 
      <TeamSection /> 

      <Row className="bottomSection largeScreenOnly">
        <Col className="bottomSectionText__wrapper">
    	    <p className="bottomSectionText"> Join us for more efficient, higher-level learning.</p>
        </Col>
        <Col className="bottomSignupLink_wrapper">
    	    <Link to={"/signup"} className="signupLinkBottom signupButtonLink blueCTA"> 
              Create a free account
          </Link>
        </Col>
        <Col className="bottomSection_boxes">
        	<div className="dotted-box white" style={{bottom:'-20px',left:'-15vw'}}></div>
        	<div className="dotted-box white" style={{bottom:'-14px',right:'40%'}}></div>
        	<div className="dotted-box white" style={{bottom:'-3px',right:'-10px'}}></div>
        	<div className="dotted-box white" style={{top:'8%',left:'50%'}}></div>
        	<div className="dotted-box white" style={{top:'-10px',right:'-130px'}}></div>
        	<div className="solid-box white" style={{top:'-5%',left:'20%'}}></div>
        </Col>
      </Row>

      <Row className="bottomSection mobileOnly">
      	<Col className="mobileWarning">
      		<svg className="mobileWarning_icon" width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M43.3892 50.2867C39.278 53.0338 34.4445 54.5 29.5 54.5C22.8719 54.4924 16.5175 51.8561 11.8307 47.1693C7.14391 42.4825 4.50756 36.1281 4.5 29.5C4.5 24.5555 5.96623 19.722 8.71326 15.6107C11.4603 11.4995 15.3648 8.29521 19.9329 6.40302C24.5011 4.51083 29.5277 4.01574 34.3773 4.98038C39.2268 5.94501 43.6814 8.32603 47.1777 11.8223C50.674 15.3186 53.055 19.7732 54.0196 24.6227C54.9843 29.4723 54.4892 34.4989 52.597 39.0671C50.7048 43.6352 47.5005 47.5397 43.3892 50.2867Z" fill="#367BF5"/>
						<path d="M23.043 23.543H35.956V36.456H23.043V23.543Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M38.3044 21.1956L43.0001 16.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M16 43.5001L20.6956 38.8044" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M21.8695 43.5002H16V37.6306" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M43.0002 22.3695V16.5H37.1306" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M38.3044 38.8015L43.0001 43.4995" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M16 16.5L20.6956 21.1956" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M16 22.3695V16.5H21.8695" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M37.1306 43.5002H43.0002V37.6306" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>

      		<p>Study Buckets is a visual learning tool, so we need more space than your current screen provides. Please expand your window or come back on tablet, laptop, or desktop.</p>
      	</Col>
      </Row>

      <div className="landingFooter">
      		<h3 className="footerTitle">study buckets</h3>
      		<p className="footerDisclaimer">© 2023 Study Buckets. All rights reserved. <span style={{display:'inline-block'}}>This site is an education tool, and is not meant to convey medical advice.</span></p>
      </div>
    </div>
  )
}