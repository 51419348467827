import { normalize } from 'normalizr';
import { INITIALIZE_NODES_DATA, INITIALIZE_QUIZ, SET_CORRECTED_CHARACTERISTICS, REMOVE_SELECTED_TYPE } from '../../constants';
import { ADD_SELECTED_TYPE, SET_SELECTED_TYPES, REMOVE_CHECK } from './constants';
import { conceptNodeEntity } from '../../schema';

const defaultState = {
  byId: {},
  selectedTypes: {},
};

export const characteristics = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case INITIALIZE_NODES_DATA: {
      const normalizedData = normalize(payload.conceptNodes, conceptNodeEntity);
      return {
        ...state,
        byId: normalizedData.entities.characteristics,
      };
    }

    case INITIALIZE_QUIZ: {
      return {
        ...state,
        byId: Object.keys(state.byId).reduce((acc, curId) => {
          acc[curId] = {
            ...state.byId[curId],
            isCorrect: false,
            isIncorrect: false,
          }

          return acc;
        }, {}),
      }
    }

    case REMOVE_CHECK: {
      return {
        ...state,
        byId: Object.keys(state.byId).reduce((acc, curId) => {
          acc[curId] = {
            ...state.byId[curId],
            isCorrect: false,
            isIncorrect: false,
          }
          return acc;
        }, {}),
      }
    }

    case SET_CORRECTED_CHARACTERISTICS: {
      const { correct, incorrect } = payload;

      const nextState = {
        ...state,
        byId: { ...state.byId },
      };

      correct.forEach(({ id }) => {
        nextState.byId[id] = {
          ...nextState.byId[id],
          isCorrect: true,
          isIncorrect: false,
        }
      });

      incorrect.forEach(({ id }) => {
        nextState.byId[id] = {
          ...nextState.byId[id],
          isIncorrect: true,
          isCorrect: false,
        }
      });

      return nextState;
    }

    case ADD_SELECTED_TYPE: {
      return {
        ...state,
        selectedTypes: {
          ...state.selectedTypes,
          [payload]: payload,
        },
      };
    }

    case REMOVE_SELECTED_TYPE: {
      const selectedTypes = { ...state.selectedTypes };
      delete selectedTypes[payload];

      return {
        ...state,
        selectedTypes,

      };
    }
    
    case SET_SELECTED_TYPES: {
      const selectedTypes = {};
      payload.forEach((type) => {
        selectedTypes[type] = type;
      });

      return {
        ...state,
        selectedTypes,
      };
    }



    default:
      return state;
  }
};
