export * from './dndTypes';
export * from './actionTypes';

export const DEFAULT_NUM_GENERATIONS = 2;

export const CATEGORY_COLORS = [
{background:'rgba(87, 202, 120, .1)', font: '#57CA78', divider: 'rgba(129, 233, 158, 0.3)' },
{background:'rgba(123, 97, 255, .1)', font: '#7B61FF', divider: 'rgba(185, 171, 255, 0.3)'  },
{background:'rgba(228, 182, 60, .1)', font: '#E4B63C', divider: 'rgba(255, 198, 47, 0.3)'},
{background:'rgba(255, 90, 90, .15)', font: '#FF5A5A' , divider: 'rgba(255, 145, 145, 0.3)' },
{background:'rgba(0, 204, 204, .1)', font: '#00CCCC' , divider: 'rgba(61, 220, 220, 0.3)' },
{background:'rgba(255, 144, 41, .2)', font: '#FF9029' , divider: 'rgba(255, 160, 72, 0.3)' },
'#198038',
'#002d9c',
'#ee538b',
'#b28600',
];



export const CATEGORY_ORDER = [
  'name',
  'aka',
  'role',
  'regulatory mechanism',
  'related diseases',
  'connects',
  'conducts',
  'starting point in CNS',
  'signal decussation',
  'end point in CNS',
  'fibers decussate...',
  'pathway',
  'location in spinal cord',
  'location of nucleus',
  'exit point',
  'path',
  'pathway',
  'travels through',
  'contains',
  'reflex',
  'clinical findings w/ palsy',
  'roots',
  'common mechanism of injury',
  'muscle innervation',
  'sensory innervation',
  'nerve roots',
  'nerve root(s)',
  'action',
  'sensation',
  'sx of injury',
  'excitatory vs inhibitory',
  'associated CNS region(s)',
  'function',
  'peripheral nerve',
  'clotting factor',
  'measured by',
  'more impacted by',
  'hormones produced',
  'class overview',
  'class',
  'generation',
  'mechanism',
  'reversibility',
  'half-life',
  'brand name',
  'drugs',
  'sometimes combined with',
  'used with',
  'use case',
  'other uses',
  'advantages',
  'benefits',
  'characteristics',
  'drawbacks',
  'potential side effects',
  'side effects',
  'contraindications',
  'risk window',
  'symptom description',
  'not live',
  'live attenuated viral vaccine',
  'onset',
  'duration',
  'defined by',
  'description',
  'overview',
  'produced in the',
  'pathophys',
  'disease overview',
  'location',
  'caused by',
  'causes',
  'most commonly associated infections',
  'causes of deficiency',
  'causes of excess',
  'organism',
  'vaccine preventable?',
  'clinical correlates',
  'class of etiology',
  'specific etiology',
  'etiology',
  'sources',
  'deficiency may cause',
  'excess may cause',
  'pathologies',
  'associated with',
  'prevalence',
  'note',
  'mutated gene',
  'associated protein buildup',
  'common causes',
  'immunofluorescence microscopy',
  'risk factors',
  'prevention',
  'timing',
  'screening',
  'counseling',
  'relationships',
  'presentation',
  'facial appearance',
  'subtype',
  'history',
  'history/clinical signs',
  'clinical signs',
  'usual age of onset',
  'population with increased risk',
  'signs and symptoms',
  'signs/symptoms/exam',
  'symptoms',
  'auscultation reveals',
  'clinical workup',
  'evaluation',
  'imaging and labs',
  'neuroimaging',
  'testing',
  'urinalysis findings',
  'lab values',
  'diagnosis',
  'x-ray shows',
  'diagnostics',
  'cancer type',
  'organism type',
  'clinical considerations',
  'intervention',
  'management',
  'treatment options',
  'treatment',
  'monitoring tx response',
  'possible complications',
  'complications',
  'possible sequelae',
  'sequelae'


];

export const TEAM_DATA = [
  {name: 'Brendan Appold, MS', role: 'Learning Design', picture: 'https://storage.googleapis.com/study_buckets_images/LandingPage/Rectangle%2041newBrendan.png'},
  {name: 'Asra Ahmed, MD', role: 'Faculty Advisor', picture: 'https://storage.googleapis.com/study_buckets_images/LandingPage/Rectangle%2038asra.png'},
  {name: 'Abrey Mann', role: 'Engineering', picture: 'https://storage.googleapis.com/study_buckets_images/LandingPage/Rectangle%2042abrey.png'},
  {name: 'Emily Giglio', role: 'Creative Direction & Design', picture: 'https://storage.googleapis.com/study_buckets_images/LandingPage/Rectangle%2038emily.png'},
  {name: 'Brian McIntosh', role: 'Engineering', picture: 'https://storage.googleapis.com/study_buckets_images/LandingPage/Brian.png'},
  {name: 'Joe Binney', role: 'Product Advisor', picture: 'https://storage.googleapis.com/study_buckets_images/LandingPage/Rectangle%2039joe.png'}
];

export const VALUE_PROPS = [
  {icon: 'https://storage.googleapis.com/study_buckets_images/LandingPage/VectoriconHands.svg', title: 'Study actively', description: 'Wrestle with content instead of passively reading and watching videos.'},
  {icon: 'https://storage.googleapis.com/study_buckets_images/LandingPage/GroupiconSpeech.svg', title: 'Compare and contrast', description: 'Identify shared vs distinct characteristics amongst related diseases, drugs, etc.'},
  {icon: 'https://storage.googleapis.com/study_buckets_images/LandingPage/GroupiconBulb.svg', title: 'Dive deeper on demand', description: 'Avoid endless paragraphs: focus on key takeaways and hover for succinct explanations and mnemonics.'},
  {icon: 'https://storage.googleapis.com/study_buckets_images/LandingPage/GroupiconTree.svg', title: 'Contextualize information', description: 'Navigate layers within a given topic with a breadcrumb trail to keep you oriented.'}
];
