import { normalize } from 'normalizr';
import { INITIALIZE_NODES_DATA, ADD_SELECTED_TYPE, REMOVE_SELECTED_TYPE, INITIALIZE_QUIZ } from '../../constants';
import { SET_HEAD_NODE_ID, CHARACTERISTIC_REMOVE, CHARACTERISTIC_DROP, RESET_DROPS, SET_DROPPED_CHARS } from './constants';
import { conceptNodeEntity } from '../../schema';

const defaultState = {
  byId: {},
  headNodeId: null,
};

export const conceptNodes = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case INITIALIZE_NODES_DATA: {
      const normalizedData = normalize(payload.conceptNodes, conceptNodeEntity);
      const newDroppedCharArray = Object.keys(normalizedData.entities.conceptNodes);
      

      return {
        ...state,
        byId: normalizedData.entities.conceptNodes,
        droppedChars: newDroppedCharArray.reduce((acc, curNode) => {
          acc[curNode] = [];
          return acc;
      }, {})
      };
    }

    case INITIALIZE_QUIZ: {
      const newDroppedCharArray = Object.keys({ ...state.byId });

      return {
        ...state,
        droppedChars: newDroppedCharArray.reduce((acc, curNode) => {
          acc[curNode] = [];
          return acc;
      }, {})
      };
    }


    case SET_HEAD_NODE_ID: {
      return {
        ...state,
        headNodeId: payload,
      }
    }

    case SET_DROPPED_CHARS: {
      const newDroppedNodes = Object.keys(payload)
      const newDroppedChars = { ...state.droppedChars }

      newDroppedNodes.forEach((node) => {
        newDroppedChars[node]= payload[node]
      });

      return {
        ...state,
        droppedChars: newDroppedChars
      }
    }


    default:
      return state;
  }
};
