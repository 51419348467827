import React, { useCallback, useMemo } from 'react';
import { Row, Col, Image, Button } from 'antd';

import { TEAM_DATA } from '../../constants';

import '../../fonts/stylesheet.css';
import './styles.scss';

export const TeamSection = () => {
	const teamMembers = TEAM_DATA.map((member) => 
        <div>
         <Image className="teamPhoto" src={member.picture} preview={false} />
         <p className="teamName"> {member.name} </p>
         <p className="teamRole"> {member.role} </p>
       </div>
    );

	
	return (
      <div id="teamAnchor"> 
        <Row className="introTeam">
          <Col xs={24} md={{span:16,offset:4}}>
            <p className="teamTitle"> Our Team </p>
            <p className="teamSubTitle"> Our team members stem from Stanford, Harvard, Teach for America, and the University of Michigan Medical School, with technology roles spanning from Robinhood to TikTok. </p>
          </Col>
        </Row>
        <Row className="teamMembers">
          <Col xs={24} md={{span:12,offset:6}} className="teamWrapper">
            {teamMembers}
          </Col>
        </Row>
      </div>
	);
}
