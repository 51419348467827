import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import './styles.scss';


export const DropCountRemaining = (props) => {
  const characteristicsData = useSelector((state) => state.characteristics)
  const nodeDropData = useSelector((state) => state.conceptNodes.droppedChars)
  const selectedTypes = useSelector((state) => state.characteristics.selectedTypes);

  const { nodeId, droppedCharCount } = props;
  const characteristicsDataById = Object.values(characteristicsData.byId);
  const characteristicsDataSelectedTypes = characteristicsData.selectedTypes;
  const count = useMemo(
    () => characteristicsDataById ? 
    characteristicsDataById.filter(char => char.correctNode === nodeId && characteristicsDataSelectedTypes[char.type]).length - 
    nodeDropData[nodeId].filter(droppedChar => selectedTypes[droppedChar.type]).length : 0
    [characteristicsData, nodeDropData, characteristicsData, selectedTypes] 
  );

  return (
    <div 
      className={classNames('itemsLeftCount', 
        { 'no-drops-left': ({count}.count === 0) },
        { 'too-much-dropped': ({count}.count < 0)}
      )}
    >
      {count} Items
    </div>
  );
};


