import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { denormalize } from 'normalizr';

import { conceptNodeEntity } from '../schema';

export const useDenormalizedNode = (nodeId) => {
  const conceptNodesById = useSelector((state) => state.conceptNodes.byId);
  const characteristicsById = useSelector((state) => state.characteristics.byId);

  return useMemo(() => {
    if (nodeId) {
      return denormalize(
        nodeId,
        conceptNodeEntity,
        {
          conceptNodes: conceptNodesById,
          characteristics: characteristicsById,
        },
      );
    }
  }, [nodeId, conceptNodesById, characteristicsById]);
};
