import { SET_HEAD_NODE_ID, CHARACTERISTIC_REMOVE, RESET_DROPS, CHARACTERISTIC_DROP, SET_DROPPED_CHARS } from './constants';

export const setHeadNodeId = (headNodeId) => ({
  type: SET_HEAD_NODE_ID,
  payload: headNodeId,
});

export const characteristicDrop = (nodeID) => ({
  type: CHARACTERISTIC_DROP,
  payload: nodeID,
});

export const characteristicRemove = (nodeID) => ({
  type: CHARACTERISTIC_REMOVE,
  payload: nodeID,
});

export const resetDrops = (headNodeId) => ({
  type: RESET_DROPS,
  payload: headNodeId,
});

export const setDroppedChars = (sortedCharacteristicIdsByNodeId) => ({
  type: SET_DROPPED_CHARS,
  payload: sortedCharacteristicIdsByNodeId,
})



