import React, { useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import mixpanel from 'mixpanel-browser';


import { ConceptNodeView } from '../ConceptNodeView';
import { TermsOfService } from '../TermsOfService';
import { PrivacyPolicy } from '../PrivacyPolicy';
import { LoginPage } from '../LoginPage';
import { SignupPage } from '../SignupPage';
import { LandingPage } from '../LandingPage';
import { SmallScreenPage } from '../SmallScreenPage';
import { useAuthDataContext } from '../../services/authDataProvider';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';


import { initializeNodesData } from '../../actions';
import nodesData from '../../dummyData.json';
import { nodePath } from '../../services';
import { PrivateRoute } from '../../components/PrivateRoute';
import { HomePageRoute } from '../../scenes/HomePageRoute';

import { useWindowDimensions } from '../../hooks/useWindowDimensions';


import './styles.scss';

const { Content } = Layout;


const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializeNodesData(nodesData));
  }, [dispatch]);
  
  //const { signOut, user } = useAuthDataContext();
  const [tooSmall, setTooSmall] = useState(false);


  

  return (
    <div className="app">
      <Layout>
          <Content className="main-content">
            <Switch> 
              <Route path="/login">
                <LoginPage />
              </Route>
              <Route path="/landing">
                <LandingPage />
              </Route>
              <Route path="/signup">
                <SignupPage />
              </Route>
              <Route path="/tos">
                <TermsOfService />
              </Route>
              <Route path="/privacypolicy">
                <PrivacyPolicy />
              </Route>
              <HomePageRoute exact path="/" component={ConceptNodeView} >  
              </HomePageRoute>
              <PrivateRoute path="/nodes/:headNodeId" component={ConceptNodeView}  >
              </PrivateRoute> 
            </Switch>
          </Content>
      </Layout>
    </div>
  );
}

export default App;
