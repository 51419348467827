import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Menu, Image, Dropdown, Button } from 'antd';
import { UpOutlined, FilterOutlined } from '@ant-design/icons';
import { addSelectedType, removeSelectedType, setSelectedTypes } from '../../scenes/Characteristics/actions';
import { useCharacteristicTypeColors } from '../../hooks/useCharacteristicTypeColors';
import { useChildmostNodes } from '../../hooks/useChildmostNodes';
import { logChangeCharacteristicTypes } from '../../services/analytics';


import './styles.scss';

export const CharacteristicLegend = ({ headNodeId }) => {
  const childmostNodes = useChildmostNodes(headNodeId);
  const numChildmostCharacteristics = useMemo(() => {
    if (childmostNodes) {
      return childmostNodes.reduce((acc, cur) => {
        return acc + (cur.characteristics ? cur.characteristics.length : 0);
      }, 0);
    } else {
      return 0;
    }
  }, [childmostNodes]);

  const dispatch = useDispatch();
  const colorByType = useCharacteristicTypeColors(headNodeId);
  const selectedTypes = useSelector((state) => state.characteristics.selectedTypes); // returns 
  const types = Object.keys(colorByType);
  const { SubMenu } = Menu;
  


  const handleChange = (e, type, flag) => {
    const { target: { checked } } = e;
    if (checked) {
      logChangeCharacteristicTypes(type,'Add');
      dispatch(addSelectedType(type));
    } else {
      logChangeCharacteristicTypes(type,'Remove');
      dispatch(removeSelectedType(type));
    }
  };

  const [visible, setVisible] = useState(false);

  const menu = (
    <Menu className = "characteristicLegendMenu" >
      {types.map((type) => (
            <Menu.Item 
              key={type} 
              className = "characteristicLegendItem" 
            >
              <div
                className="legend-item"
                key={type}
                style={{ 
                  backgroundColor: colorByType[type].background, 
                  color: colorByType[type].font,
                  borderRadius:  "0px 0px 5px 5px",
                  lineHeight: "24px",
                  fontSize: "16px",
                  height: "32px",
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "5px"

                }}
              >
                <Checkbox checked={selectedTypes[type]} onChange={(e) => handleChange(e, type)} />
                  {type}
              </div>
            </Menu.Item>
          ))}
   </Menu>





  );


  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }

  return (
    <div 
      className="characteristic-legend"
      style = {{
          marginTop: "3px",
        }}

    >
    {numChildmostCharacteristics === 0 ?
      <Dropdown
        className = "characteristicMenu"
        overlay={menu} 
        placement = "bottomRight"
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <Button
          disabled={numChildmostCharacteristics === 0}
          style = {{
            borderRadius: "50%",
            height: "40px",
            width: "40px",
            background: "#1890ff",
            padding: "5px 7px",
            boxShadow: "0px 2px 4px rgba(54, 123, 245, 0.25), 0px 4px 8px rgba(54, 123, 245, 0.15)",
            visibility: "hidden",
          }}>
          <FilterOutlined  
              style={{
                zIndex: "999",
                color: "#ffffff",
                fontSize: "24px"
              }}
          />
   
        </Button>     
    </Dropdown> :
      <Dropdown
        className = "characteristicMenu"
        overlay={menu} 
        placement = "bottomRight"
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <Button
          disabled={numChildmostCharacteristics === 0}
          style = {{
            borderRadius: "50%",
            height: "40px",
            width: "40px",
            background: "#1890ff",
            padding: "5px 7px",
            boxShadow: "0px 2px 4px rgba(54, 123, 245, 0.25), 0px 4px 8px rgba(54, 123, 245, 0.15)",
          }}>
          <FilterOutlined  
              style={{
                zIndex: "999",
                color: "#ffffff",
                fontSize: "24px"
              }}
          />
   
        </Button>     
    </Dropdown>
  }
   
    </div>
  );
};

CharacteristicLegend.propTypes = {
  headNodeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
