import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SteppedLineTo } from 'react-lineto';
import { useLocation } from 'react-router-dom';
import { useDrop, onDrop } from 'react-dnd';
import Chance from 'chance';
import mixpanel from 'mixpanel-browser';


import { ConceptNode } from '../../components/ConceptNode';
import { CHARACTERISTIC_ITEM_TYPE } from '../../constants';
import { useRenderGridOfNodeIds } from '../../hooks/useRenderGridOfNodeIds';
import { useChildmostNodes } from '../../hooks/useChildmostNodes';
import { logVisitView, logLeaveView } from '../../services/analytics';

import './styles.scss';

export const ConceptNodes = (props) => {
  const {
    headNodeId, renderBelowNode, renderBelowMap, renderAboveMap, showPearls, randomized, onDrop, onHover, marginBottom, mode
  } = props;

  

  const [{ canDrop }, drop] = useDrop({
      accept: CHARACTERISTIC_ITEM_TYPE,
      drop: (item, monitor) => { 
        const overBackground = monitor.isOver({ shallow: true })
        if (overBackground) {
          onDrop(item)
        }
      },
      hover: (item, monitor) => {
        const clientOffset = monitor.getClientOffset()
        if (clientOffset) {
      // you can implement your own scroll behavior here

      // scroll up if dragging to an upper area
          const moveThreshold = Math.max(120, window.innerHeight / 4);

          if (clientOffset.y < moveThreshold ) {
        // scroll up
        //window.isScrolling = true;
            window.scroll({top: 0, left: 0, behavior: 'smooth' })
          /*$("html, body").animate({
            scrollTop: window.pageYOffset - moveThreshold,
          },
          {
            duration: 300,
            complete: () => {
              window.isScrolling = false;
            },
          });*/
        }
      }
    }   
  });

  const handleDiagramNav = useCallback(() => {
   const data = {
      navMode: 'Diagram',
      oldView: headNodeId,
    };
    logLeaveView(data);
  }, []);


  const randomSeed = useMemo(() => {
    if (randomized) {
      const chance = new Chance();
      return chance.random();
    }
  }, [randomized]);

  const renderGrid = useRenderGridOfNodeIds(headNodeId, { randomSeed });
  const childmostNodes = useChildmostNodes(headNodeId);
  const childmostNodeById = useMemo(
    () => childmostNodes
      ? childmostNodes.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {})
      : {},
    [childmostNodes]
  );

  const { pathname } = useLocation();





   const renderLines = useCallback(() => {
       if (renderGrid) {
        const delay = 0;
        return (
          <div className= {"line-container"}>
            {renderGrid.slice(1,renderGrid.length).map((row) => {
              return row.map((node) => headNodeId !== node.id && (
                  <div 
                    className = {`line-from-${node.parentId}-to-${node.id}-${mode}`}
                    key = {`line-from-${node.parentId}-to-${node.id}-${mode}`}
                  >
                  <SteppedLineTo
                    key={`key-${node.id}-${pathname}`}
                    className="connectorLine"
                    borderWidth={2}
                    borderColor="#56CCF2"
                    within='main-content'
                    from={`concept-node-${node.parentId}`}
                    to={`concept-node-${node.id}`}
                    delay={delay}
                    fromAnchor="center 100%"
                    toAnchor="center 0%"
                  />
                </div>
              ))
            })}
          </div>
        );
      }

      
  }, [renderGrid, headNodeId, pathname]);


  if (!renderGrid) return null;

  const [[{ numColumns }]] = renderGrid;
  const numRows = renderGrid.length;

  return (
    <>
      {renderAboveMap && (
        <>
          {renderAboveMap()}
          <br style={{ clear: 'both' }} />
        </>

      )}

      {renderLines()}


     

      <div 
        className="concept-nodes-show"
        style={{
          gridTemplateColumns: `repeat(${numColumns}, ${100 / numColumns}%`,
          gridTemplateRows: 'auto',
  
        }}
        ref={drop}
      >
        {renderGrid.slice(1,renderGrid.length).map((row) => { //slice out first row here
          if (row[0].rowIndex === 2)
          {
            return row.map((node) => (
              <div
                className={`concept-node-cell concept-node-cell-${node.id}`}
                id = {`concept-node-cell-${node.id}`}
                key={`node-${node.id}`}
                style={{
                  gridColumnStart: node.startColumn,
                  gridColumnEnd: node.startColumn + node.numColumns,
                  gridRow: node.rowIndex-1,
                  minHeight: "0px",
                  marginBottom: {marginBottom}
                }}
                onClick={handleDiagramNav}
              >
                <ConceptNode 
                  nodeId={node.id} 
                  showPearl={showPearls} 
                />
              </div>
          
          ));
        }

        else {
          return row.map((node) => (
              <div
                className={`concept-node-cell concept-node-cell-${node.id}`}
                id = {`concept-node-cell-${node.id}`}
                key={`node-${node.id}`}
                style={{
                  gridColumnStart: node.startColumn,
                  gridColumnEnd: node.startColumn + node.numColumns,
                  gridRow: node.rowIndex-1,
                  minHeight: "0px",
                  marginBottom: {marginBottom}
                }}
                onClick={handleDiagramNav}
              >
                <ConceptNode 
                  nodeId={node.id} 
                  showPearl={showPearls} 
                />
              </div>
          
          ));


        }
        })}


        {renderGrid.map((row) => {
          return row.map((node) => node.isChildmostNode && renderBelowNode && (
            <div
              className="sub-chldmost-node-area"
              key={`sub-node-${node.id}`}
              style={{
                gridColumnStart: node.startColumn,
                gridColumnEnd: node.startColumn + node.numColumns,
                gridRowStart: node.rowIndex,
                gridRowEnd: numRows+1
              }}
            >
              {renderBelowNode(childmostNodeById[node.id])}

            </div>


          ));


        })}


        {renderBelowMap && (
          <div
            style={{
              gridColumnStart: 1,
              gridColumnEnd: numColumns + 1,
            }}
          >
            {renderBelowMap()}


          </div>
        )}
      </div>
    </>
  );
};

ConceptNodes.propTypes = {
  headNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  renderBelowNode: PropTypes.func,
  renderBelowMap: PropTypes.func,
  renderAboveMap: PropTypes.func,
  showPearls: PropTypes.bool,
  randomized: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

ConceptNodes.defaultProps = {
  renderBelowNode: () => {},
  renderBelowMap: () => {},
  renderAboveMap: () => {},
  showPearls: false,
  randomized: false,
}
