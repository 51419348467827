import { INITIALIZE_NODES_DATA, INITIALIZE_QUIZ, SET_CORRECTED_CHARACTERISTICS, REMOVE_SELECTED_TYPE, ADD_SELECTED_TYPE } from './constants';

export const initializeNodesData = (nodesData) => ({
  type: INITIALIZE_NODES_DATA,
  payload: nodesData,
});

export const initializeQuiz = () => ({ type: INITIALIZE_QUIZ });

export const setCorrectedCharacteristics = (correct, incorrect) => ({
  type: SET_CORRECTED_CHARACTERISTICS,
  payload: {
    correct,
    incorrect,
  },
});


export const removeSelectedType = (type) => ({
  type: REMOVE_SELECTED_TYPE,
  payload: type,
});


export const addSelectedType = (type) => ({
  type: ADD_SELECTED_TYPE,
  payload: type,
});

