import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { InfoCircleFilled } from  '@ant-design/icons';
import { logViewPearl } from '../../services/analytics';


import './styles.scss';

export const Pearl = ({ text }) => {
  const [visible, setVisible] = useState(false)
  const handleHoverChange = () => {
    setVisible(!visible);
    if (visible) {logViewPearl() }
  }

  const renderPopoverContent = (
    <div className="pearl-content" dangerouslySetInnerHTML={{ __html: text }} />
  );

  return (
    <Popover 
      placement="topRight" 
      content={renderPopoverContent}
      onVisibleChange={handleHoverChange}
    >
     <InfoCircleFilled 
      className="pearl" 
     />
    </Popover>
  );
};

Pearl.propTypes = {
  text: PropTypes.string.isRequired,
};
