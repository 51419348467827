import React from 'react';
import './styles.scss';
import { Image } from 'antd';


export const LoadingScreen = () => { 
  return (
    <div className = "loadingGifDiv">
      <Image 
        className = "loadingGif" 
        src={'https://storage.googleapis.com/study_buckets_images/LandingPage/StudyBuck-favicon_600x600_v2-transparent'} 
        preview={false}
      />
    </div>
  )
}