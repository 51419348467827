import React, { useCallback, useState, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useAuthDataContext } from '../../services/authDataProvider';
import { LoginPage } from '../../scenes/LoginPage';
import { LoadingScreen } from '../../components/LoadingScreen';
import { LandingPage } from '../../scenes/LandingPage';
import { nodePath } from '../../services';

import firebase from '../../services/firebase';



export const HomePageRoute = ({ authenticated, component, ...options }) => {
  //const { authenticated } = props;
  

  const [user, setUser] = useState(() => firebase.auth().currentUser || undefined);
  const loadingUser = (user === undefined);

  useEffect(() => firebase.auth().onAuthStateChanged(setUser), []);

  if (loadingUser) {
    return <LoadingScreen />; // or show loading icon, etc.
  }
  


  return (
  <Route {...options} >
    {!!user ? <Redirect to={nodePath(1)} /> : <LandingPage />}
  </Route>
)

 
  
};