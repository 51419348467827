import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Row } from 'antd';
import mixpanel from 'mixpanel-browser';


import { ConceptNodes } from '../ConceptNodes';
import { Characteristic } from '../../components/Characteristic';
import { CharacteristicLegend } from '../../components/CharacteristicLegend';
import { useCharacteristicTypeColors } from '../../hooks/useCharacteristicTypeColors';
import { useChildmostNodes } from '../../hooks/useChildmostNodes';
import { nodePath, quizPath } from '../../services';
import { CATEGORY_ORDER } from '../../constants';
import { logModeBegin, logModeEnd } from '../../services/analytics';

export const ReviewView = ({ headNodeId }) => {
  
  const handleClickQuiz = useCallback(() => {
    logModeEnd('Review');
    logModeBegin('Quiz');
  }, []);

  const selectedTypes = useSelector((state) => state.characteristics.selectedTypes);
  const typeToColor = useCharacteristicTypeColors(headNodeId);
  const childmostNodes = useChildmostNodes(headNodeId);
  const numChildmostCharacteristics = useMemo(() => {
    if (childmostNodes) {
      return childmostNodes.reduce((acc, cur) => {
        return acc + (cur.characteristics ? cur.characteristics.length : 0);
      }, 0);
    } else {
      return 0;
    }
  }, [childmostNodes]);

  

  const marginBottomReview = "20px";
  const displayNone = 'none';
  const displaySolid = 'solid';


  const renderBelowNode = useCallback((node) => (
    <>
      {node.characteristics && node.characteristics.sort((a,b) => CATEGORY_ORDER.indexOf(a.type) - CATEGORY_ORDER.indexOf(b.type)).map((element, index, elements) => 
          selectedTypes[element.type] && (
          <Characteristic
            key={element.id}
            id={element.id}
            type = {element.type}
            showPearl
            color={typeToColor[element.type].background}
            fontColor= {typeToColor[element.type].font}
            bottomBorderStyle =  { 
              (elements[index+1] ? (element.type === elements[index+1].type ? 'solid' : 'none') : 'none')
            }
            bottomBorderColor = {typeToColor[element.type].divider}
            nodeId = {node.id}
          />
      ))}
    </>
  ), [selectedTypes, typeToColor]);

  const renderAboveMap = useCallback(() => (
    

    <Row 
      className="button-row right-aligned"
      style = {{
        marginTop: "25px",
        marginBottom: "25px"
      }}
    >
        <Button
          className = "quizButton"
          disabled={numChildmostCharacteristics === 0}
          style = {{
            boxShadow: "0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24)",
            borderRadius: "10px",
            marginTop: "5px",
            marginRight: "20px",
            fontFamily: "CircularXXWeb-Medium",
            fontStyle: "normal",
            fontWeight: "bold",
            color: "#BDBDBD",
            letterSpacing: "0.75px",
            width: "96px",
            height: "40px"
          }}
          onClick={handleClickQuiz}
        >
        <Link 
          to={quizPath(headNodeId)}
          className = "quizLink"
        >
          QUIZ
        </Link>
    </Button>

      
        <Button
          className = "reviewButton"
          disabled={numChildmostCharacteristics === 0}
          type="primary"
          style = {{
            boxShadow: "0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24)",
            borderRadius: "10px",
            marginTop: "5px",
            marginRight: "20px",
            fontFamily: "CircularXXWeb-Medium",
            fontStyle: "normal",
            fontWeight: "bold",
            letterSpacing: "0.75px",
            width: "96px",
            height: "40px"
          }}
        >
          <Link to={nodePath(headNodeId)}>
            REVIEW
          </Link>
        </Button> 
      
        <CharacteristicLegend headNodeId={headNodeId}  />
    </Row>
    
  ), [headNodeId]);


  
  return (
    <>
      <ConceptNodes
        headNodeId={headNodeId}
        renderBelowNode={renderBelowNode}
        renderAboveMap={renderAboveMap}
        marginBottom = {marginBottomReview}
        mode = 'review'
        showPearls
      />
      
    </>
  );

};

ReviewView.propTypes = {
  headNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
