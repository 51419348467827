import { Layout, Row, Col, Image, Button, Dropdown, Menu} from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';


import '../../fonts/stylesheet.css';
import './styles.scss';

const mobileMenu = (
  <Menu >
    <Menu.Item key="aboutLink" >
      <HashLink smooth to="/landing#valuePropsAnchor">About</HashLink>
    </Menu.Item>
    <Menu.Item key="teamLink" >
      <HashLink smooth to="/landing#teamAnchor">Team</HashLink>
    </Menu.Item>
  </Menu>
);


export const LandingHeader = () => {

  const [menuIsOpen, setMenuIsOpen] = useState(false);


  return (
  		<div className="headerWrapper">
	      <div className = 'mobileHeader mobileOnly'>  
            <div className="left-side-header">
              <HashLink smooth to="/landing#landingPage">
                <h1 className='websiteNameMobile'>study buckets </h1>
              </HashLink>
            </div>
          
            <div className="right-side-header" onClick={() => setMenuIsOpen(!menuIsOpen)}>
              <Dropdown.Button 
                overlay={mobileMenu} 
                placement="bottomLeft" 
                icon={menuIsOpen ? <CloseOutlined /> : <MenuOutlined />}
                trigger={['click']}
                overlayStyle = {{
                  minWidth: "100%"
                }}
              >
              </Dropdown.Button>
            </div>
	      </div>
	      <div className= "lp_header largeScreenOnly">
  	      	<div className="header_group header_left">
              <NavHashLink smooth to="landing#landingPage">
  	            <h1 className = 'header-brand'>
  	              study buckets 
  	            </h1>
              </NavHashLink>
	            <NavHashLink to="/landing#valuePropsAnchor" smooth className="headerLink">About</NavHashLink>
	            <NavHashLink to="/landing#teamAnchor" smooth className="headerLink">Team</NavHashLink>
	          </div>

       
          	<div className="header_group header_right">
	            <Link to="/signup" className="headerSignupButton signupButtonLink blueCTA headerCTA">
						      Sign up
	            </Link>  
	            <Link to="/login" className="loginLink">
	                Log in >
	            </Link>
          </div>
	      </div>
    </div>
      );
}