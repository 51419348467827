import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Row, Button } from 'antd';
import { useDispatch } from 'react-redux';
import mixpanel from 'mixpanel-browser';



import { nodePath, quizPath, reviewPath } from '../../services';
import { initializeQuiz } from '../../actions';
import { logModeBegin, logModeEnd } from '../../services/analytics';



export const ModeButtons = ({ headNodeId }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  


  const handleMoveToReview = useCallback(() => {
    dispatch(initializeQuiz());
    logModeEnd('Quiz');
    logModeBegin('Review');
  }, [ dispatch]);


  const renderQuizButtons = (
    <>
      <Button
          type="primary"
          style = {{
            boxShadow: "0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24)",
            borderRadius: "10px",
            marginTop: "5px",
            marginRight: "20px",
            fontFamily: "CircularXXWeb-Medium",
            fontFeatureSettings: "ss03 1",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "0.75px",
            width: "96px",
            height: "40px"

          }}

        >
        <Link to={nodePath(headNodeId)}>
          QUIZ
        </Link>
      </Button>

      <Button 
          onClick={handleMoveToReview}
          style = {{
            boxShadow: "0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24)",
            borderRadius: "10px",
            marginTop: "5px",
            marginRight: "20px",
            fontFamily: "CircularXXWeb-Medium",
            fontFeatureSettings: "ss03 1",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#BDBDBD",
            letterSpacing: "0.75px",
            width: "96px",
            height: "40px"
          }}
          
      >
        <Link to={reviewPath(headNodeId)}
          className = "move-to-review">
          REVIEW
        </Link>
      </Button>

    </>
  );

   
  
  return (
    <>
      {pathname === quizPath(headNodeId) && renderQuizButtons}
    </>
  );
};

ModeButtons.propTypes = {
  headNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
