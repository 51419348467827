import { useMemo } from 'react';
import { uniq } from 'lodash';

import { CATEGORY_COLORS, CATEGORY_ORDER } from '../constants';
import { useChildmostNodes } from './useChildmostNodes';

export const useCharacteristicTypeColors = (nodeId) => {
  const childmostNodes = useChildmostNodes(nodeId);

  const characteristics = useMemo(() => {
    return childmostNodes
      ? childmostNodes.reduce((acc, cur) => acc.concat(cur.characteristics || []), [])
      : [];
  }, [childmostNodes]);

  const types = useMemo(() => {
    return uniq(characteristics.map(c => c.type)).sort((a,b) => { 
      return CATEGORY_ORDER.indexOf(a) - CATEGORY_ORDER.indexOf(b) 
    }) 
  }, [characteristics]);


  const typeToColor = useMemo(() => {
    return types.reduce((acc, cur, index) => {
      acc[cur] = CATEGORY_COLORS[index];
      return acc;
    }, {});
  }, [types]);


  return typeToColor;
};
