import { useMemo, useCallback } from 'react';
import { sumBy } from 'lodash';
import Chance from 'chance';
import mixpanel from 'mixpanel-browser';


import { useDenormalizedNode } from './useDenormalizedNode';
import { DEFAULT_NUM_GENERATIONS } from '../constants';

export const useRenderGridOfNodeIds = (nodeId, { randomSeed }) => {
  mixpanel.register({
        "View":  nodeId 
  });

  const denormalizedHeadNode = useDenormalizedNode(nodeId);
  const numGenerations = useMemo(() => {
    return (denormalizedHeadNode && denormalizedHeadNode.numGenerations) || DEFAULT_NUM_GENERATIONS;
  }, [denormalizedHeadNode]);
  
  const traverseTreeToGrid = useCallback((node, numberGen, nodeIndex = 0, result = []) => {
    const { id, parent: parentId } = node;
    const genIndex = numberGen - 1;
    const rowIndex = numGenerations - genIndex;

    if (!result[genIndex]) {
      result[genIndex] = [];
    }

    const siblings = result[genIndex];

    let insertIndex;
    if (randomSeed) {
      const chance = new Chance(randomSeed, nodeIndex, genIndex);
      insertIndex = chance.integer({ min: 0, max: siblings.length });
      
    } else {
       insertIndex = siblings.length;
    }

    if (genIndex === 0 || !node.children || node.children.length === 0) {
      siblings.splice(insertIndex, 0, {
        id,
        parentId,
        numColumns: 1,
        rowIndex,
        isChildmostNode: true,
      });

      return result;
    }

    node.children.forEach((child, index) => {
      traverseTreeToGrid(child, numberGen - 1, index, result);
    });

    const children = result[genIndex - 1].filter((child) => child.parentId === node.id);
    const numColumns = sumBy(children, (child) => child.numColumns) || 1;

    siblings.splice(insertIndex, 0, {
      id,
      parentId,
      numColumns,
      rowIndex,
    });

    return result;
  }, [randomSeed, numGenerations]);

  const renderGrid = useMemo(() => {
    if (denormalizedHeadNode) {
      const reversedGrid = traverseTreeToGrid(denormalizedHeadNode, numGenerations);
      const grid = reversedGrid.reverse();

      grid.forEach((row, rowIndex) => {
        if (rowIndex === 0) {
          row[0].startColumn = 1;
        } else {
          const previousRow = grid[rowIndex - 1];

          row.forEach((node, nodeIndex) => {
            const parent = previousRow.find((n) => n.id === node.parentId);
            const siblingOffset = row
              .slice(0, nodeIndex)
              .filter((n) => n.parentId === node.parentId)
              .reduce((acc, cur) => acc + cur.numColumns, 0);
            node.startColumn = parent
              ? parent.startColumn + siblingOffset
              : siblingOffset;
          });
        }
      });

      return grid; 
    }
  }, [traverseTreeToGrid, denormalizedHeadNode, numGenerations]);

  return renderGrid;
};
