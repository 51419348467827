import React, { useCallback, useMemo } from 'react';
import { Row, Col, Image, Button } from 'antd';
import '../../fonts/stylesheet.css';
import './styles.scss';

import { VALUE_PROPS } from '../../constants';

export const ValueProps = () => {
	const valueProps = VALUE_PROPS.map((prop) => 
       <div className="valueProposition">
           <Image className="valuePropIcon" src={prop.icon} preview={false} />
           <p className="valuePropTitle"> {prop.title} </p>
           <p className="valuePropDescription"> {prop.description} </p>
       </div>

    );

	
	return (
    <div id="valuePropsAnchor"> 
      <Row className="introValueProps">
        <Col xs={24} md={{ span: 16, offset: 4}}>
          <p className="valuePropsTitle">
            Flashcards meet concept mapping for active, conceptual learning
          </p>
        </Col>
        <Col xs={{ span: 22, offset: 1}} sm={{ span: 18, offset: 3}} md={{ span: 12, offset: 6}} lg={{ span: 10, offset: 7}}>
          <Image className = "valuePropGraphic" src={'https://storage.googleapis.com/study_buckets_images/LandingPage/VennDiagram-DesktopvennDiagram.png'} preview={false}/>
        </Col>
      </Row>
      <Row className="valueProps">
        <Col className="propsWrapper" xs={24} md={{span: 22, offset: 1}} lg={{span: 18, offset: 3}}>
          {valueProps}
        </Col>
      </Row>
    </div>
	);
}
