import { useCallback, useMemo } from 'react';

import { useDenormalizedNode } from './useDenormalizedNode';
import { DEFAULT_NUM_GENERATIONS } from '../constants';

export const useChildmostNodes = (nodeId) => {
  const denormalizedNode = useDenormalizedNode(nodeId);
  const numGenerations =
    (denormalizedNode && denormalizedNode.numGenerations) || DEFAULT_NUM_GENERATIONS;
  const initialGenIndex = numGenerations - 1;

  const traverse = useCallback((node, genIndex) => {
    let result = [];

    if (genIndex === 0 || !node.children || node.children.length === 0) {
      return [{
        ...node,
        rowIndex: initialGenIndex - genIndex,
      }];
    }

    node.children.forEach((child) => {
      result = result.concat(traverse(child, genIndex - 1));
    });

    return result;
  }, [initialGenIndex]);

  const childmostNodes = useMemo(() => {
    if (denormalizedNode) {
      return traverse(denormalizedNode, initialGenIndex);
    }
  }, [traverse, denormalizedNode, initialGenIndex]);

  return childmostNodes;
};
