import React from 'react';
import { Avatar, Layout, Menu, Button, Dropdown } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { useAuthDataContext } from '../../services/authDataProvider';
import { Link } from 'react-router-dom';


import './styles.scss';

export const Header = () => {
  const { signOut, user } = useAuthDataContext();
  const { SubMenu } = Menu;

  const menu = (
  <Menu className="profileMenu">
    <Menu.Item key="logoutOption" onClick={signOut} >
      Logout
    </Menu.Item>
    <Menu.Item key="moreOptions" >
      My Account
    </Menu.Item>
  </Menu>
);
//



  return (
    <Layout.Header className="header">
      <div className="left-side-header-logged-in">
        <p 
          className = "siteTitle"
          style={{ 
            color: 'white',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '24px',
            lineHeight: '30px'
          }}
        >
          <Link to= "/nodes/1"
            style={{ 
                  color: '#FFFFFF',
                }} 
          > study buckets
          </Link>
        </p>
      </div>


      <div className="right-side-header-logged-in">
        <Dropdown.Button 
          className = {!!user ? "profileIcon" : "signedOutAvatarProfileIcon"}
          overlay={menu} 
          placement="bottomLeft"
          icon={ <Avatar size={30} src = {!!user ? user.photoURL : <UserOutlined/> } /> }
          style = {{
              backgroundColor: "#367BF5",
              color: "#ffffff",
              zIndex: "999999",
              paddingRight: "0px",
            }}
        >
        </Dropdown.Button>
      </div>
    </Layout.Header>
    
  );
};

      