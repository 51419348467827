import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import firebase from '../services/firebase';
import * as firebaseui from 'firebaseui';
import 'firebase/auth';
import 'firebaseui/dist/firebaseui.css';
import mixpanel from 'mixpanel-browser';
import { logUserChange, logLogout } from '../services/analytics';



export const AuthDataContext = createContext(null);

const AuthDataProvider = props => {
  const [user, setUser] = useState();
  const [loginUI, setLoginUI] = useState();
  var currentUID;
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if(!!user) {
        mixpanel.identify(user.uid);
      } 
      logUserChange(user);
      setUser(user);
    });
    
    setLoginUI(new firebaseui.auth.AuthUI(firebase.auth()));
  }, []);

  const signOut = () => {
    firebase.auth().signOut().then(() => {
      logLogout();
      setUser(null);  
    });
  };

  const authDataValue = useMemo(() => {
    return { user, loginUI, signOut };
  }, [user]);

  return <AuthDataContext.Provider value={authDataValue} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;