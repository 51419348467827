import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import mixpanel from 'mixpanel-browser';


import { useSiblingNodes } from '../../hooks/useSiblingNodes';
import { nodePath } from '../../services';
import { Pearl } from '../Pearl';
import { logLeaveView, logVisitView } from '../../services/analytics';


import './styles.scss';

export const NavigationDropdown = ({ nodeId, headNodeId }) => {
  const siblingNodes = useSiblingNodes(nodeId);
  const handleBreadcrumbNav = useCallback((node) => {
   const data = {
      navMode: 'Breadcrumb',
      oldView: headNodeId,
      newView: node
    };
    logLeaveView(data);
    logVisitView(data);
  }, []);

  if (!siblingNodes || siblingNodes.length === 0) return null;
  
  return (
    <Menu
      style={{ 
        height: "100%", 
        borderRight: 0,
        boxShadow: "0px 6px 12px rgba(54, 123, 245, 0.16)",
      }}
    >
      {siblingNodes.map((node) => (
        node.id != nodeId ? 
        <Menu.Item 
          key={node.id} 
          className='navigation-dropdown-cell' 
          onClick= {() => handleBreadcrumbNav(node.id)}
        >
          <Link 
            to= {!node.children ? nodePath(node.parent) : node.children.length > 0 ? nodePath(node.id) : nodePath(node.parent)}
            className="navDropdownLink"
          >
            {node.title}
          </Link>
        </Menu.Item>
        : ''
      ))}
    </Menu>
  );
};

NavigationDropdown.propTypes = {
  nodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
