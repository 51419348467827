import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import { Row, Col, Button } from 'antd';
import { LandingHeader } from '../../components/LandingHeader';
import { LoadingScreen } from '../../components/LoadingScreen';
import { PrivateRoute } from '../../components/PrivateRoute';
import { ConceptNodeView } from '../../scenes/ConceptNodeView';
import mixpanel from 'mixpanel-browser';


import 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

import { useAuthDataContext } from '../../services/authDataProvider';
import './styles.scss';




const uiConfig = {
  signInSuccessUrl: '/nodes/1',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Google'
    }
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: '/tos',
  // Privacy policy url/callback.
  privacyPolicyUrl: '/privacypolicy',
};



export const LoginPage = () => {
  const { loginUI, loaded } = useAuthDataContext();

  useEffect(() => {
    if (loginUI) {
      loginUI.start('#firebaseui-auth-container', uiConfig);
    }
  }, [loginUI]);

      return (
        <div id="firebaseui-auth-container" className="login-page">
          <LandingHeader/>
          <div className="loginContent">
            <p className="loginTitle"> Hello </p>
            <p className="signupOption"> Sign in to Study Buckets or <Link to={"/signup"} className="signupLink"> Sign up </Link> </p>
          </div>
        </div> 
      ) 


  
};