export const firebaseConfig = {
  apiKey: 'AIzaSyD21GvxoxDSXf-PAl_3C6OXcn1nLPupygQ',
  authDomain: 'auth.studybuckets.com',
  projectId: 'grokr-c7ce3',
  storageBucket: 'grokr-c7ce3.appspot.com',
  messagingSenderId: '895322278628',
  appId: '1:895322278628:web:2262eec090098c62d27af1',
  measurementId: 'G-8VBHT66CY7',
}

//question can I change authDomain to auth.studybuckets.com without breaking it? 