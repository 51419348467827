import React from 'react';
import { Image } from 'antd'
import './styles.scss';






export const SmallScreenPage = () => {
  
  return (
    <div id="smallScreenPage" className="smallScreenPage">
      <div  className="smallScreenIconDiv" style={{ textAlign: 'center', paddingTop: '5%' }} >
        <Image src="https://storage.googleapis.com/study_buckets_images/LandingPage/Expand-Screen-Iconexpandicon.png" />
      </div>
      <div id="smallScreenMessage" className="smallScreenMessage">
      
        <p className="smallScreenText"> 
          Study Buckets is a visual learning tool, so we need more space than your current screen provides. Please come back on a tablet, laptop, or desktop.
        </p>
      </div>
    </div>
  );
};