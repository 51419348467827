import firebase from './firebase';
import mixpanel from 'mixpanel-browser';
import 'firebase/analytics';


const analytics = firebase.analytics();

mixpanel.init('a4b94ab8e24ef1cffbd00291fce54e30', {debug: true}); 

export const logUserChange = (data) => {
  if(data) {
  if(data.createdAt && data.createdAt === data.lastLoginAt) {
    mixpanel.alias(data.uid)
    mixpanel.track('Sign up');
  }
  else {
    mixpanel.track('Log in')
    mixpanel.identify(data.uid)
  }

  }
  
}


export const logModeBegin = (mode) => {
  mixpanel.track('Mode begin', {
    'Mode': mode === 'Quiz' ? 'Quiz' : 'Review'
  });

  if(mode ==='Quiz') {
     mixpanel.time_event('Check quiz');
  }
  mixpanel.time_event('Mode end')
}

export const logModeEnd = (mode) => {
  mixpanel.track('Mode end', {
    'Mode': mode === 'Quiz' ? 'Quiz' : 'Review'
  });
}

export const logLogout = () => {
  mixpanel.track('Logout');
  mixpanel.reset();
}


export const logVisitView = (data) => {
  mixpanel.track('Visit view', {
    'Nav mode': data.navMode,
    'View': data.newView
  });
  mixpanel.time_event('Leave view')
}

export const logLeaveView = (data) => {
  mixpanel.track('Leave view', {
    'Nav mode': data.navMode,
    'View': data.oldView
  });
}

export const logViewPearl = () => {
  mixpanel.track('View pearl');
}

export const logChangeCharacteristicTypes = (type, action) => {
  mixpanel.track('Change characteristic types', {
    'Action': action,
    'Characteristic type': type
  });
}

export const logSortIntoBucket = (node, characteristic, type) => {
  mixpanel.track('Sort', {
    'Node': node,
    'Characteristic': characteristic,
    'Characteristic type': type
  });
}

export const logCheckQuiz = (correctLength, incorrectLength, categoriesLength, possibleCategoriesLength) => {
  mixpanel.track('Check quiz', {
    'Num correct': correctLength,
    'Num incorrect': incorrectLength,
    'Pct correct': 100*(correctLength)/(incorrectLength + correctLength),
    //'Num categories': categoriesLength,
    //'Pct categories': 100*(categoriesLength)/(possibleCategoriesLength),
  });
}



export const logScreenSizeError = (data) => {
  mixpanel.track('Screen size error');
}



export const landingPageView = (data) => {
  mixpanel.track('Landing page view');
}

export const signupPageView = (data) => {
  mixpanel.track('Sign up page view');
}

export const loginPageView = (data) => {
  mixpanel.track('Log in page view');
}





export const logQuizEntered = (data) => {
  analytics.logEvent('quiz_started', data);
};

export const logQuizCompleted = (data) => {
  analytics.logEvent('quiz_completed', data);
};

export const logReviewEntered = (data) => {
  analytics.logEvent('review_started', data);
};

export const logNodeClicked = (data) => {
  analytics.logEvent('node_clicked', data);
};

export default analytics;