import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Row } from 'antd';


import { reviewPath, quizPath } from '../../services';
import { useChildmostNodes } from '../../hooks/useChildmostNodes';
import { ConceptNodes } from '../ConceptNodes';
import { logQuizEntered, logReviewEntered } from '../../services/analytics';

export const MapView = ({ headNodeId }) => {
  const childmostNodes = useChildmostNodes(headNodeId);
  const numChildmostCharacteristics = useMemo(() => {
    if (childmostNodes) {
      return childmostNodes.reduce((acc, cur) => {
        return acc + (cur.characteristics ? cur.characteristics.length : 0);
      }, 0);
    } else {
      return 0;
    }
  }, [childmostNodes]);

  const renderAboveMap = useCallback(() => {
    const handleStartQuiz = () => logQuizEntered(headNodeId);
    const handleStartReview = () => logReviewEntered(headNodeId);

    return (
      <Row className="button-row right-aligned">
          <Button 
            disabled={numChildmostCharacteristics === 0} 
            onClick={handleStartQuiz}
            style = {{
              boxShadow: "0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24)",
              borderRadius: "10px",
              marginTop: "5px",
              marginRight: "20px",
              fontFamily: "CircularXXWeb-Medium",
              fontStyle: "normal",
              fontWeight: "bold",
              color: "#BDBDBD",
              wordSpacing: "-.5em",
              letterSpacing: "0.75px",
              width: "96px",
              height: "40px",
            }}

          >
            <Link to={quizPath(headNodeId)}>
              QUIZ
            </Link>
          </Button>

        
          <Button 
            disabled={numChildmostCharacteristics === 0} 
            onClick={handleStartReview}
            style = {{
              boxShadow: "0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24)",
              borderRadius: "10px",
              marginTop: "5px",
              marginRight: "100px",
              fontFamily: "CircularXXWeb-Medium",
              fontStyle: "normal",
              fontWeight: "bold",
              color: "#BDBDBD",
              wordSpacing: "-.5em",
              letterSpacing: "0.75px",
              width: "96px",
              height: "40px"

            }}
          >
            <Link to={reviewPath(headNodeId)}>
              REVIEW
            </Link>
          </Button>
      </Row>
    );
  }, [headNodeId, numChildmostCharacteristics]);

  return (
    <ConceptNodes 
      headNodeId={headNodeId} 
      renderAboveMap={renderAboveMap}
      mode = 'map' 
      showPearls />
  );
};

MapView.propTypes = {
  headNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
