import { useMemo } from 'react';
import { uniq } from 'lodash';

import { CATEGORY_ORDER } from '../constants';
import { useChildmostNodes } from './useChildmostNodes';

export const useCharacteristicOrderList = (inputArr) => {
const orderedCharacteristicsByType = useMemo(() => {
	return inputArr.sort((a,b)=> {
		return CATEGORY_ORDER.indexOf(a.type) - CATEGORY_ORDER.indexOf(b.type) 
	})
}, [inputArr]);

return orderedCharacteristicsByType;
}


