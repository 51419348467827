import React from 'react';
import { Layout, Image, Row, Col } from 'antd';

import './styles.scss';

export const Footer = () => (
  <Layout.Footer className="footer">
    <Row>
      <Col xs={{ span: 24, offset: 0 }} md={{ span: 9, offset: 2 }}  lg={{ span: 5, offset: 2 }} className = "footerLogoColumn">
        <div className="footerLogoLoggedIn">
          <Image
            width={32}
            preview={false}
            src="https://storage.googleapis.com/study_buckets_images/LandingPage/favicon%20-%2032x32favicon.png"
          />
        </div>
      </Col>
      <Col xs={{ span: 24, offset: 0 }} md={{ span: 13, offset: 0 }} lg={{ span: 13, offset: 4 }} className = "footerTextColumn">
        <div className="footerTextLoggedIn">
          © 2023 Study Buckets. All rights reserved. This site is an education tool, and is not meant to convey medical advice.
        </div>
      </Col>
    </Row>
  </Layout.Footer>
)
