import { schema } from 'normalizr';

export const conceptNodeEntity = new schema.Entity('conceptNodes', {}, {
  processStrategy: (value, parent) => {
    return {
      ...value,
      parent: parent.id,
      droppedChars: 0 ,
      maxCharacteristics: value.characteristics !== undefined  ? value.characteristics.length : 0 ,
    };
  },
});

export const characteristicEntity = new schema.Entity('characteristics', {}, {
  idAttribute: (value, parent) => `${parent.id}-${value.type}-${value.text}`,
  processStrategy: (value, parent) => ({
    ...value,
    id: `${parent.id}-${value.type}-${value.text}`,
    correctNode: `${parent.id}`,
    type: `${value.type}`
  }),
});

conceptNodeEntity.define({
  children: [conceptNodeEntity],
  characteristics: [characteristicEntity],
});

