import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useNodeLineage = (nodeId) => {
  const conceptNodesById = useSelector((state) => state.conceptNodes.byId);

  return useMemo(() => {
    let curNode = conceptNodesById[nodeId];
    if (!curNode) return [];

    const lineage = [curNode];

    while (curNode && curNode.parent && curNode.id !== curNode.parent) {
      const parentNode = conceptNodesById[curNode.parent];
      lineage.unshift(parentNode);
      curNode = parentNode;
    }

    return lineage;
  }, [nodeId, conceptNodesById]);
};
