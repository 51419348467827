import { SET_SELECTED_TYPES, REMOVE_CHECK } from './constants';
import { ADD_SELECTED_TYPE, REMOVE_SELECTED_TYPE } from '../../constants';

export const addSelectedType = (type) => ({
  type: ADD_SELECTED_TYPE,
  payload: type,
});

export const setSelectedTypes = (types) => ({
  type: SET_SELECTED_TYPES,
  payload: types,
});

export const removeSelectedType = (type) => ({
  type: REMOVE_SELECTED_TYPE,
  payload: type,
});

export const removeCheck = () => ({ type: REMOVE_CHECK });