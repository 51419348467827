import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { setHeadNodeId } from '../ConceptNodes/actions';
import { SmallScreenPage } from '../SmallScreenPage';
import { MapView } from '../MapView';
import { ReviewView } from '../ReviewView';
import { QuizView } from '../QuizView';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { NavigationBar } from '../../components/NavigationBar';
import { useCharacteristicTypeColors } from '../../hooks/useCharacteristicTypeColors';
import { setSelectedTypes } from '../../scenes/Characteristics/actions';
import { useAuthDataContext } from '../../services/authDataProvider'
import './styles.scss';



export const ConceptNodeView = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch(); 
  const { headNodeId } = useParams();
  const colorByType = useCharacteristicTypeColors(headNodeId);
  const types = Object.keys(colorByType);
  const { user } = useAuthDataContext();

  const intercomName = user ? user.displayName : '';
  const intercomEmail = user ? user.email : '';
  const intercomCreatedAt = user ? user.createdAt : '';

 // }
  

  window.intercomSettings = {
    app_id: "cklemr0u",
    name: intercomName, // Full name
    email: intercomEmail, // Email address
    created_at: intercomCreatedAt // Signup date as a Unix timestamp
  };

// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/cklemr0u'
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/cklemr0u';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();


  useEffect(() => {
      if (headNodeId) {
        dispatch(setSelectedTypes(types))
      }
  }, [headNodeId]);


  useEffect(() => {
    if (headNodeId) {
      dispatch(setHeadNodeId(headNodeId));
    }
  }, [dispatch, headNodeId]);


  return (
    <>
      <Header /> 
      <div 
          className= "conceptNodesView"
          style = {{
            minHeight: "calc(100vh - 106px)"
          }}>
          <NavigationBar headNodeId={headNodeId} />
            <Switch>
              <Route exact path={path}>
                <ReviewView headNodeId={headNodeId} />
              </Route>
              <Route path={`${path}/review`}>
                <ReviewView headNodeId={headNodeId} />
              </Route>
              <Route path={`${path}/quiz`}>
                <QuizView headNodeId={headNodeId} />
              </Route>
            </Switch>
            </div>
            <div className="tooSmall">
              <SmallScreenPage />
            </div> 
        <Footer />

    </>
  );
};
