import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useSiblingNodes = (nodeId) => {
  const conceptNodesById = useSelector((state) => state.conceptNodes.byId);

  return useMemo(() => {
    const node = conceptNodesById[nodeId];
    const parentNode = node ? conceptNodesById[node.parent] : null;

    if (parentNode && parentNode.id !== node.id && parentNode.children) {
      return parentNode.children.map((childId) => conceptNodesById[childId]);
    }

    return [];
  }, [nodeId, conceptNodesById]);
};
