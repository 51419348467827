import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDrop, onDrop } from 'react-dnd';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { Button, Row } from 'antd';
import * as _ from 'lodash';
import Chance from 'chance';


import { ConceptNodes } from '../ConceptNodes';
import { CHARACTERISTIC_ITEM_TYPE, CATEGORY_ORDER } from '../../constants';
import { CharacteristicLegend } from '../../components/CharacteristicLegend';
import { DroppableCategory } from '../../components/DroppableCategory';
import { DropCountRemaining } from '../../components/DropCountRemaining';
import { Characteristic } from '../../components/Characteristic';
import { ModeButtons } from '../../components/ModeButtons';
import { useChildmostNodes } from '../../hooks/useChildmostNodes';
import { useCharacteristicTypeColors } from '../../hooks/useCharacteristicTypeColors';
import { useCharacteristicOrderList } from '../../hooks/useCharacteristicOrderList';
import { useCorrectQuiz } from '../../hooks/useCorrectQuiz';
import { reviewPath } from '../../services';
import { initializeQuiz, setCorrectedCharacteristics } from '../../actions';
import { characteristicRemove, characteristicDrop, resetDrops, setDroppedChars } from '../ConceptNodes/actions';
import { removeCheck } from '../Characteristics/actions';
import { logQuizCompleted, logModeBegin, logModeEnd, logSortIntoBucket, logCheckQuiz } from '../../services/analytics';





export const QuizView = ({ headNodeId }) => {
   mixpanel.register({
        "Mode": "Quiz"
    });
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const [corrected, setCorrected] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [instantCheck, setInstantCheck] = useState(false);
  const [randomSeed, setRandomSeed] = useState();
  const [redirectPath, setRedirectPath] = useState();
  const [unsortedCharacteristicIds, setUnsortedCharacteristicIds] = useState([]);
  const [unsortedCharacteristicIdsDropStatus, setUnsortedCharacteristicIdsDropStatus] = useState([]);
  const [sortedCharacteristicIdsByNodeId, setSortedCharacteristicIdsByNodeId] = useState({});
  const childmostNodes = useChildmostNodes(headNodeId);
  const typeToColor = useCharacteristicTypeColors(headNodeId);
  const orderByType = useCharacteristicOrderList(unsortedCharacteristicIds);
  const correctQuiz = useCorrectQuiz();

  const characteristicsById = useSelector((state) => state.characteristics.byId);
  const selectedTypes = useSelector((state) => state.characteristics.selectedTypes);


  const getCharacteristicIds = useCallback((nodeId) => {
    return sortedCharacteristicIdsByNodeId[nodeId] || [];
  }, [sortedCharacteristicIdsByNodeId]);
  

  const initializeState = useCallback(() => {
    if (childmostNodes) {
      setUnsortedCharacteristicIds(
        _.shuffle(
          childmostNodes.reduce((acc, curNode) => {
            return curNode.characteristics && curNode.characteristics.length > 0
              ? acc.concat(curNode.characteristics.map((c) =>  {
                  return c.id
                }))
              : acc;
          }, []),
        ),
      );
      setUnsortedCharacteristicIdsDropStatus(
        unsortedCharacteristicIds.reduce((acc, curChar) => {
          acc[curChar]= false;
          return acc;
        }, {})
      );
      setSortedCharacteristicIdsByNodeId(
        childmostNodes.reduce((acc, curNode) => {
          acc[curNode.id] = [];
          return acc;
        }, {})
      );

    }
  }, [childmostNodes]);

  const handleInit = useCallback(() => {
    if (childmostNodes) {
      initializeState();
      dispatch(setDroppedChars(sortedCharacteristicIdsByNodeId));
      dispatch(initializeQuiz());
      setInitialized(true);
      setPristine(true);
      setCorrected(false);
      setInstantCheck(false);
      setRandomSeed(new Chance().random());
    }
  }, [dispatch, initializeState, childmostNodes]);


  
  useEffect(() => {
    if (!initialized) {
      handleInit();
    }
  }, [handleInit, initialized]);
  

  const handleCheckAnswers = useCallback(() => {
    const { correct, incorrect } = correctQuiz(sortedCharacteristicIdsByNodeId);
    logCheckQuiz(correct.length, incorrect.length)
    dispatch(setCorrectedCharacteristics(correct, incorrect));
    setCorrected(true);
    setInstantCheck(true);
    logQuizCompleted({ correct, incorrect });
  }, [correctQuiz, sortedCharacteristicIdsByNodeId, dispatch]);

  const handleDone = useCallback(() => {
    dispatch(initializeQuiz());
    setRedirectPath(reviewPath(headNodeId));
  }, [headNodeId, dispatch]);

  const handleRemoveCheck = useCallback(() => {
    setInstantCheck(false);
    dispatch(removeCheck());
  }, [correctQuiz, sortedCharacteristicIdsByNodeId, dispatch])
  

  const handleDrop = useCallback((nodeId, item) => {
    logSortIntoBucket(nodeId, item.id, item.type)
    dispatch(characteristicDrop(nodeId));

    item.curLocation = nodeId;
    const nextUnsortedCharacteristicIds = [...unsortedCharacteristicIds];
    const nextUnsortedCharacteristicsIdsDropStatus = {...unsortedCharacteristicIdsDropStatus};
    nextUnsortedCharacteristicsIdsDropStatus[item.id] = true;
    setUnsortedCharacteristicIdsDropStatus(nextUnsortedCharacteristicsIdsDropStatus);
    _.remove(nextUnsortedCharacteristicIds, id => id === item.id);
    setUnsortedCharacteristicIds(nextUnsortedCharacteristicIds);
    const nextSortedCharacteristicIdsByNodeId = { ...sortedCharacteristicIdsByNodeId };

    Object.keys(nextSortedCharacteristicIdsByNodeId).forEach((nodeId) => {
      if (
          nextSortedCharacteristicIdsByNodeId[nodeId].filter(c => c.id === item.id).length > 0) 
          {
            dispatch(characteristicRemove(nodeId))
          }
      _.remove(nextSortedCharacteristicIdsByNodeId[nodeId], char => char.id === item.id);
    });

    nextSortedCharacteristicIdsByNodeId[nodeId].push({id: item.id, type: item.charType});
    nextSortedCharacteristicIdsByNodeId[nodeId] =  nextSortedCharacteristicIdsByNodeId[nodeId].sort((a,b)=> {
      return CATEGORY_ORDER.indexOf(a.type) - CATEGORY_ORDER.indexOf(b.type) 
    });
    setSortedCharacteristicIdsByNodeId(nextSortedCharacteristicIdsByNodeId);
    if (instantCheck) {
      const { correct, incorrect } = correctQuiz(sortedCharacteristicIdsByNodeId);
      dispatch(setCorrectedCharacteristics(correct, incorrect));
    }
    dispatch(setDroppedChars(sortedCharacteristicIdsByNodeId));

    setPristine(false);
  }, [sortedCharacteristicIdsByNodeId, unsortedCharacteristicIds, handleCheckAnswers]);

  const renderCharacteristics = useCallback((characteristicIds) => (
    <>
      {characteristicIds.map((characteristicId) => {
        const { type } = characteristicId;
        
        return selectedTypes[type] != undefined && (
          <Characteristic 
                 draggable id={characteristicId.id} 
                 key={characteristicId.id} 
                 color={typeToColor[type].background}
                 fontColor={typeToColor[type].font}
          />
        ) 
      
      })}
    </>
  ), [getCharacteristicIds, typeToColor, selectedTypes]);

  //

  const renderBelowNode = useCallback((node) => (
    <div className="underNodeQuiz">
      <DropCountRemaining nodeId = {node.id} />  
      <DroppableCategory nodeId={node.id} onDrop={handleDrop}>
        {renderCharacteristics(getCharacteristicIds(node.id))}
      </DroppableCategory>
   </div>
  ), [getCharacteristicIds, handleDrop, renderCharacteristics]);

  

  const groupedCharacteristicsByText = useMemo(() => {
    const result = {};
    unsortedCharacteristicIds.forEach(characteristicId => {
      const { id, text, type } = characteristicsById[characteristicId];
      const key = type + '-' + text;
      const groupedCharacteristics = result[key] 
      result[key] = {
        id,
        text,
        type,
        count: groupedCharacteristics ? groupedCharacteristics.count + 1 : 1,
      };
    });
    return result;
  }, [characteristicsById, unsortedCharacteristicIds]);

  const renderUnsortedCharacteristics = useCallback((inputType) => {
      return Object.keys(groupedCharacteristicsByText).map((text) => {
        const { id, count, type } = groupedCharacteristicsByText[text];

        return type == inputType && selectedTypes[type] && (
          <Characteristic 
            draggable id={id} 
            key={id} 
            color={typeToColor[type].background} 
            fontColor={typeToColor[type].font} 
            bottomBorderStyle = "solid" 
            bottomBorderColor={typeToColor[type].divider} 
            count={count} 
            nodeId="bottomArea"
          />
          )       
    });
  }, [groupedCharacteristicsByText, selectedTypes, typeToColor]);
  

  const handleReturn = useCallback((item) => {
      const nextUnsortedCharacteristicsIdsDropStatus = {...unsortedCharacteristicIdsDropStatus};
      const nextUnsortedCharacteristicIds = [...unsortedCharacteristicIds];
      const nextSortedCharacteristicIdsByNodeId = { ...sortedCharacteristicIdsByNodeId };
      const indexOfType = nextUnsortedCharacteristicIds.indexOf(nextUnsortedCharacteristicIds.find(element => element.charType === item.charType));     

      if (unsortedCharacteristicIdsDropStatus[item.id]===true) {
        nextUnsortedCharacteristicIds.splice(indexOfType, 0, item.id);
      }
      setUnsortedCharacteristicIds(nextUnsortedCharacteristicIds);
      nextUnsortedCharacteristicsIdsDropStatus[item.id] = false;
      setUnsortedCharacteristicIdsDropStatus(nextUnsortedCharacteristicsIdsDropStatus);
      const nextUnsortedNodeIds = Object.keys(nextSortedCharacteristicIdsByNodeId);     
      Object.keys(nextSortedCharacteristicIdsByNodeId).forEach((nodeId) => {
        if (
          nextSortedCharacteristicIdsByNodeId[nodeId].filter(c => c.id === item.id).length > 0) 
          {
            dispatch(characteristicRemove(nodeId))
          }
        _.remove(nextSortedCharacteristicIdsByNodeId[nodeId], char => char.id === item.id);
      });   
      setSortedCharacteristicIdsByNodeId(nextSortedCharacteristicIdsByNodeId);
      dispatch(setDroppedChars(sortedCharacteristicIdsByNodeId));

     
  }, [sortedCharacteristicIdsByNodeId, unsortedCharacteristicIds]);



  const renderTypeStacks = useCallback(() => {
    return Object.keys(selectedTypes).map((cType) => {
      const color = typeToColor[cType].background
      const fontColor = typeToColor[cType].font
      const bottomBorderColor = typeToColor[cType].divider
      return selectedTypes[cType] != undefined && (
        <TypeStack 
          key = {`typestack-${cType}`}
          nodeId={'bottomArea'} 
          onDrop={handleReturn} 
          color = {color} 
          cType = {cType}
          fontColor = {fontColor} 
          bottomBorderColor = {bottomBorderColor}
        > 
        </TypeStack>
      ) 
    });
  }, [renderUnsortedCharacteristics, selectedTypes, typeToColor]);

  const TypeStack = (props) => {
    const { color, fontColor, cType, bottomBorderColor, onDrop, typeToColor, nodeId } = props;//
    const [{ canDrop }, drop] = useDrop({

      accept: CHARACTERISTIC_ITEM_TYPE,
      drop: (item) => onDrop(item),
      
    });

    const backColor = "linear-gradient(0deg," + color+ "," + color + "), #FFFFFF";




  return (
  <div 
    className="characteristicStack" 
    style={{ 
      width: "15%",
    }}
  >
    <div 
      className="typeName"
      style = {{
        background: backColor,
        color: fontColor,
        fontFamily: "CircularXXWeb-Medium",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        padding: "5%",
        borderBottomStyle: "solid",
        borderBottomColor: bottomBorderColor
      }}
    >
      {cType}
    </div>
    <div 
      className="undraggedCharacteristics"
      ref = {drop}
    >
      {renderUnsortedCharacteristics(cType)}
    </div>
  </div>
  )
}

  const renderAboveMap = useCallback(() => (
    <Row 
      className="button-row right-aligned"
      style = {{
        marginTop: "25px",
        marginBottom: "25px"
      }}
    >
      <Button 
        onClick={handleInit} 
        style = {{
              boxShadow: "0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24)",
              borderRadius: "10px",
              marginTop: "5px",
              marginRight: "20px",
              fontFamily: "CircularXXWeb-Medium",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "18px",
              letterSpacing: "0.75px",
              color: "#BDBDBD",
              width: "96px",
              height: "40px"

        }}
      >
        RESET
      </Button>
        {(instantCheck ? 
          <Button 
            type="primary"
            onClick={handleRemoveCheck} 
            style = {{
              boxShadow: "0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24)",
              borderRadius: "10px",
              marginTop: "5px",
              marginRight: "80px",
              fontFamily: "CircularXXWeb-Medium",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "18px",
              letterSpacing: "0.75px",
              color: "#ffffff",
              width: "96px",
              height: "40px"

            }}
          >
            CHECK
          </Button> :
          <Button 
            onClick={handleCheckAnswers} 
            disabled={pristine}
            style = {{
              boxShadow: "0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24)",
              borderRadius: "10px",
              marginTop: "5px",
              marginRight: "80px",
              fontFamily: "CircularXXWeb-Medium",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "18px",
              letterSpacing: "0.75px",
              color: "#BDBDBD",
              width: "96px",
              height: "40px"

            }}
          >
            CHECK
          </Button>
        )}
        <div 
          className="dividerLineQuiz"
          style={{
            //position: "absolute",
            width: "56px",
            height: "0px",
            border: "1px solid #367BF5",
            transform: "rotate(90deg)",
            marginTop: "26px",
            marginLeft: "-40px",
            marginRight: "40px"

          }}

        >
        </div>
      <ModeButtons headNodeId={headNodeId} />
      <CharacteristicLegend headNodeId={headNodeId} renderBelowNode = {renderBelowNode} />
    </Row>
  ), [headNodeId, corrected, pristine, handleReturn, handleDrop, handleRemoveCheck]);

  const renderBelowMap = useCallback(() => (
    <>
      <Row 
        align="middle"
        style={{
          display: "flex", 
          flex: "1", 
          flexDirection: "row", 
          justifyContent: "space-evenly", 
          alignItems: "flex-start"
        }}>
        {renderTypeStacks()}
      </Row>
    </>
  )
  , [handleCheckAnswers, selectedTypes, handleDone, handleInit, pristine, corrected]);
  

  return (
    <>
      <ConceptNodes
        onDrop={handleReturn}
        headNodeId={headNodeId}
        randomized={randomSeed}
        renderBelowNode={renderBelowNode}
        renderBelowMap={renderBelowMap}
        renderAboveMap={renderAboveMap}
        marginBottom = "0px"
        mode = "quiz"
      />
      {redirectPath && <Redirect to={redirectPath} />}
    </>
  );
};

QuizView.propTypes = {
  headNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
